<template>
<div>
    <Wrapper>
        <Sidebar slot="sidebar" />
        <Navbar slot="navbar" />
        <Footer slot="footer" />
        <div slot="content">
            <!-- <input type="text" v-model="filters.name.value"> -->
            <div class="container-fluid">
                <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div>

  <h4 class="mb-2 head-font mt-3">

                    <img src="../../assets/images/chev.png" loading="lazy" alt="" height="18" @click="$router.go(-1)" class="pr-4 pb-1">
                    User Profile </h4>

   <section id="tabs" class="project-tab">
            <nav>
              <div class="nav nav-tabs nav-fill" style="max-width: 78%;" id="nav-tab" role="tablist">
               
                <a
                  class="nav-item nav-link active"
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#nav-profile"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >Profile</a>

                  <a
                  class="nav-item nav-link"
                  id="nav-security-tab"
                  data-toggle="tab"
                  href="#nav-security"
                  role="tab"
                  aria-controls="nav-security"
                  aria-selected="false"
                >Next of Kin</a>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              


              <!-- PROFILE NAV -->
              <div
                class="tab-pane show active fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                         
 <div class="display-div tab-div-2" style="padding:20px 5px">
                  <div class="row">
                    <div class="col-md-4 col-12 pb-3">
                       <div class="text-center">
    <img src="../../assets/images/avatar.png"  style="height:100px; width:100px; border-radius:50%">
                       </div>
                       <hr>
                    </div>
                    <div class="col-md-5 col-12">
                        <div style="min-height:30px; display:flex;" class="text-center">
   <p class=" mb-2" style="color:#000">{{userProfileInfo.email}}</p>

                        </div>
            
            
                    </div>
                    
                  </div>
                </div>

 <div class="display-div tab-div-2 mt-4" style="padding:20px 5px">
                  <div class="row">
                    <div class="col-md-4 col-sm-12 col-12 pb-3">
                       <div >
    <h5 class="fw-600">Full Name</h5>
    <p style="color:#828282">Your name on the platform</p>
                       </div>
                    </div>
                    <div class="col-md-8 col-sm-12  col-12">
                        <div style="display:flex; flex-direction:column">
<div style="flex-basis:100%" >
    <p style="color:#828282">First Name</p>
                <input  v-model="userProfileInfo.first_name" type="text" autocomplete="off" :class="{'disable-input':!editProfile , 'enable-input':editProfile }" style="min-width:100%" placeholder="Surname" >
                        </div>
                                       
<div style="flex-basis:100%" class="mt-3">
    <p style="color:#828282">Last Name</p>
                                    <input  v-model="userProfileInfo.last_name" style="min-width:100%"   type="text"  :class="{'disable-input':!editProfile, 'enable-input':editProfile}" autocomplete="off" placeholder="First name" >
              
                        </div>
                        </div>
                        <div class="pb-3" v-if="!editProfile">
                        </div>
            <hr v-if="!editProfile && userProfileInfo.bvn == null || !editProfile && userProfileInfo.bvn == ''" >
            <hr v-if="editProfile" >
            <div class="pb-3 pt-2" v-if="!editProfile && userProfileInfo.bvn == null ||!editProfile && userProfileInfo.bvn == ''" @click.stop="editUserProfile">

 <button class="text-left" style="background:#fff; border:1px solid #fff; font-weight:300; padding:0; font-size: 14px; min-height:40px; min-width:150px; color:#0094FF;border-radius:3px; min-width:100px"  >
                                                         
                                               Edit details
     
            </button>
            </div>
            <!-- <p style="color:#0094FF;" class="pt-3 pb-4 point-cursor">Edit details</p> -->

            <div class="pb-3 pt-2"  v-if="editProfile">

            <button class="mr-4 " style="background:transparent; border:1px solid transparent;min-width:150px; color:#4F4F4F" @click="stopEditUserProfile">
                   <p>Cancel</p> 
            </button>
            <button style="background:#0094FF; border:1px solid #0094FF; font-weight:300; min-height:40px; min-width:150px; color:#fff;border-radius:3px; min-width:100px"  @click="updateUserProfile">
                     
                                                
                                           <p>     {{editProfileLoader ? '' : 'Save Changes'}}</p>
                                                
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="editProfileLoader">
                                         </span>
            </button>
            </div>
                    </div>
                    
                  </div>
                </div>
 <div class="display-div tab-div-2 mt-4" style="padding:20px 5px">
                  <div class="row">
                   
                    <div class="col-md-4 col-12 pb-3">
                        <div>

                        <h5 class="fw-600">Phone Number</h5>
                        </div>
                       
                    </div>
     <div class="col-md-6 col-12">

<div style="display:flex;">
<div style="flex-basis:0%">
                    <p class="" style="color:#000">{{userProfileInfo.phone | spaceOutNumbers}}</p>
                        </div>
                                       
<div style="flex-basis:20%">
                                          <button style="background:transparent; color:#0094FF; border-radius:3px; border:1px solid transparent;font-size:12px; min-width:70px"  class="text-left" @click="userClicksOnChangePhone">
                                       
                                              Change
                                            
                                            </button>
                        </div>
                        </div>
</div>
                      
    
                    
                    
                  </div>
                </div>


                </div>

                <!-- NEXT OF KIN TAB -->
              <div
                class="tab-pane fade"
                id="nav-security"
                role="tabpanel"
                aria-labelledby="nav-security-tab"
              >
                         
 <div class="display-div tab-div-2" style="padding:20px 5px">
                  <div class="row pb-3">
                    <div class="col-md-4 col-12 pb-3">
                       <div >
    <h5 class="fw-600">Next of Kin</h5>
    <p style="color:#828282">Details of your successor</p>
                       </div>
                    </div>
                    <div class="col-md-6 col-12">
                        <div style="display:flex;">
<div >
    <p style="color:#828282">Full Name</p>
                <input  v-model="userProfileInfo.next_kin_name" type="text" autocomplete="off" :class="{'disable-input':!editNextOfKin , 'enable-input':editNextOfKin }" placeholder="Full name" style="min-width:100%; max-width:100%" autofocus>
                        </div>
                                       

                        </div>
                    </div>
                    
                  </div>
                </div>
 <div class="display-div tab-div-2 mt-3" style="padding:20px 5px">
                  <div class="row">
                   
                    <div class="col-md-4 col-12 pb-3">
                        <div>

                        <h5 class="fw-600">Relationship</h5>
                          <p style="color:#828282">Mother, Brother, Sister</p>
                        </div>
                       
                    </div>
     <div class="col-md-6 col-12">

<div style="display:flex;">
   <input  v-model="userProfileInfo.next_kin_relationship" type="text" autocomplete="off" :class="{'disable-input':!editNextOfKin , 'enable-input':editNextOfKin }" placeholder="Relationship" style="min-width:100%; max-width:100%">

                        </div>
</div>
                      
    
                    
                    
                  </div>
                </div>
 <div class="display-div tab-div-2 mt-3" style="padding:20px 5px"> 
                  <div class="row pb-3" >
                   
                    <div class="col-md-4 col-12 pb-3">
                        <div>

                        <h5 class="fw-600">Email Address</h5>
                          
                        </div>
                       
                    </div>
     <div class="col-md-6 col-12">

<div style="display:flex;">
  <input  v-model="userProfileInfo.next_kin_email" type="text" autocomplete="off" :class="{'disable-input':!editNextOfKin , 'enable-input':editNextOfKin }" placeholder="Email" style="min-width:100%; max-width:100%">

                        </div>
</div>
                      
    
                    
                    
                  </div>
                </div>
 <div class="display-div tab-div-2 mt-3" style="padding:20px 5px">
                  <div class="row pb-3" >
                   
                    <div class="col-md-4 col-12 pb-3">
                        <div>

                        <h5 class="fw-600">Phone Number</h5>
                          
                        </div>
                       
                    </div>
     <div class="col-md-6 col-12">

<div style="display:flex;">
  <input  v-model="userProfileInfo.next_kin_phone" type="text" autocomplete="off" :class="{'disable-input':!editNextOfKin , 'enable-input':editNextOfKin }" placeholder="Phone Number" style="min-width:100%; max-width:100%">

                        </div>
</div>
                      
    
                    
                    
                  </div>
                </div>
  <div class="display-div tab-div-2" style="align-items:center; min-height:56px; margin-top:-20px;padding:20px 5px" >
                  <div class="row" >
                   
                    <div class="col-md-4 col-12">
                      
                       
                    </div>
     <div class="col-md-6 col-12">

<div class="">
     <button class="text-left" style="background:#fff; border:1px solid #fff; font-weight:300; padding:0; font-size: 14px; min-height:40px; min-width:150px; color:#0094FF;border-radius:3px; min-width:100px" v-if="!editNextOfKin"  @click.stop="editNextOfKinMethod">
                   
                                                
                                               Edit details
                                                
                                           
                                         
            </button>
          

            <button class="mr-4" style="background:transparent; border:1px solid transparent;min-width:150px;    font-size: 14px; color:#4F4F4F" v-if="editNextOfKin"                @click="stopEditNextOfKin">
                    Cancel
            </button>
            <button style="background:#0094FF; border:1px solid #0094FF; font-weight:300;     font-size: 14px; min-height:40px; min-width:150px; color:#fff;border-radius:3px; min-width:100px" v-if="editNextOfKin" @click="updateNextOfKin">
                   
                                                
                                                {{nextOfKinLoader ? '' : 'Save Changes'}}
                                                
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="nextOfKinLoader">
                                         </span>
            </button>
            </div>
</div>
                      
    
                    
                    
                  </div>
                </div>
                </div>
            </div>
        </section>









                <template v-if="searchString == ''">
                    <div>
                        <div class="profile-div pb-4">
             

























                                                                            <div class="modal fade" id="referralModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                                                <div class="modal-dialog modal-dialog-centered" role="document">
                                                                                    <div class="modal-content">
                                                                                        <div class="modal-header text-right" style="border-bottom:1px solid #F2F2F2 !important;">

                                                                                            <h4 class="modal-title head-font" id="exampleModalLongTitle"> {{tran.referrals}}
                                                                                            <span style="color:#828282;; font-size:16px">            ({{referraLength}}) 
                                                                                                -<b><span>&#8358;</span>{{formatNumber(referralTotalAmount)}}</b>
                                                                                                 </span>
                                                                                       </h4>
                                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                              <span aria-hidden="true">&times;</span>
                                                                                             </button>
                                                                                        </div>
                                                                                   
                                                                                        <div class="modal-body">
                                                                                            <div  v-for="ref in userProfileInfo.referral" :key="ref.index" style="display:flex; border-bottom:0.5px solid #F2F2F2;" class="mb-3">
                                                                                                <div>
                                                                                              <p class="pb-1"><b> {{ref.first_name}} {{ref.last_name}}</b></p>
                                                                                            <p style="color:#828282;">{{ref.ref_code}}</p>
                                                                                                </div>
                                                                                        
                                                                                                <div>
                                                                                              <p v-if="ref.referral_amount !== null" class="table-btn">   <span>&#8358;</span> {{ ref.referral_amount }}</p>
                                                                                              <p v-else class="fade-ele-btn">    <span>&#8358;</span> 0.00</p>
                                                                                        
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                          <div class="modal fade bd-example-modal-sm" id="verifyPhone"   aria-labelledby="mySmallModalLabel"  data-keyboard="false" data-backdrop="static" aria-hidden="true">
                                                                            <div class="modal-dialog modal-sm modal-dialog-centered" style="padding-right:0 !important">
                                                                                    <div class="modal-content" style="border-radius:5px !important;" v-if="userProfileInfo.phone_verified_at == null && false">
                                                                                        <div class="modal-header text-right" style="border-bottom:1px solid #F2F2F2 !important;    display: flex;
    align-items: center;">

                                                                                            <h6 class="modal-title" id="exampleModalLongTitle" style="font-size:13px">
                                                                                                 Enter token sent to <b>{{userProfileInfo.phone}}</b>
                                                                                         
                                                                                       </h6>
                                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                              <span aria-hidden="true">&times;</span>
                                                                                             </button>
                                                                                        </div>
                                                                                   
                                                                                        <div class="modal-body">
                                                                                            <div  style="display:flex; border-bottom:0.5px solid #F2F2F2;border:1px solid #fff" class="mb-3">
                                                                                                <input type="text" v-model="phoneToken" class="mr-1 form-input" id="phoneNumber">
                                                                                            
                                                                                           
                                                                                            </div>
                                                                                        </div>

                                                                                        <div  style="display:flex;background:#fff; justify-content:space-around; padding:20px 0px; border-bottom-left-radius:10px; border-bottom-right-radius:10px; border-top:2px solid #E0E0E0;">
                                                                                            <button data-dismiss="modal" aria-label="Close" style="border:1px solid #fff; background:#fff;">cancel</button>
                                                                                            <button style="border:1px solid #BDBDBD; background:#fff;border-radius:5px; min-width:100px" @click="verifyPhoneToken" >
                                                                                                
                                                                                                  {{ verifyingToken ? '':'   Send verification code' }}
                                                                                                 <i class="fa fa-spin fa-spinner" v-if="verifyingToken"></i>
                                                                                                
                                                                                             </button>
                                                                                        </div>
                                                                                    </div>

                                                                                           <div class="modal-content" style="border-radius:5px !important;" >
                                                                                        <div class="modal-header text-right" style="border-bottom:1px solid #F2F2F2 !important;    display: flex;
    align-items: center;">

                                                                                            <h6 class="modal-title" id="exampleModalLongTi tle" style="font-size:13px" v-if="numberUnchanged">
                                                                                                 Enter new phone number
                                                                                         
                                                                                       </h6>
                                                                                            <h6 class="modal-title" id="exampleModalLongTitle" style="font-size:13px" v-if="!numberUnchanged">
                                                                                                Enter token sent to <b>{{userProfileInfo.phone}}</b>
                                                                                         
                                                                                       </h6>
                                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                              <span aria-hidden="true">&times;</span>
                                                                                             </button>
                                                                                        </div>
                                                                                   
                                                                                        <div class="modal-body">
                                                                                            <div  style="display:flex; border-bottom:0.5px solid #F2F2F2;border:1px solid #fff" class="mb-3">
                                                                                                <input type="text" v-model="phoneToken" class="mr-1 form-input" id="phoneNumber" v-if="!numberUnchanged">
                                                                                                <input type="text" v-model="userProfileInfo.phone" class="mr-1 form-input" id="phoneNumber" v-if="numberUnchanged">
                                                                                            
                                                                                           
                                                                                            </div>
                                                                                        </div>

                                                                                        <div  style="display:flex;background:#fff; justify-content:space-around; padding:20px 0px; border-bottom-left-radius:10px; border-bottom-right-radius:10px; border-top:2px solid #E0E0E0;">

                                                                                            <button data-dismiss="modal" aria-label="Close" style="border:1px solid #fff; background:#fff;" v-if="numberUnchanged">cancel</button>



<p @click="userWantsToEditPhone" class="point-cursor" style="border:1px solid #fff; background:#fff; font-size:13px" v-if="!numberUnchanged">
Edit phone number

</p>


                                                                                            <button style="border:1px solid #BDBDBD; background:#fff;border-radius:5px; min-width:100px" @click="ChangePhoneNumber" v-if="numberUnchanged">
                                                                                                
                                                                                                  {{ verifyingPhone ? '':'   Send verification code' }}
                                                                                                 <i class="fa fa-spin fa-spinner" v-if="verifyingPhone"></i>
                                                                                                
                                                                                             </button>
                                                                                            <button style="border:1px solid #BDBDBD; background:#fff;border-radius:5px; min-width:100px" @click="verifyPhoneToken" v-if="!numberUnchanged">
                                                                                                
                                                                                                  {{ verifyingToken ? '':'   Verify' }}
                                                                                                 <i class="fa fa-spin fa-spinner" v-if="verifyingToken"></i>
                                                                                                
                                                                                             </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
































                                                                            <!-- Testimonial Modal  -->

                                                                              <div class="modal fade" id="exampleModal2" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="showModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" >
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                    </button>
                  
                </div>
                <!-- <div class="modal-header" v-if="testimonialData.testimony !== ''">
          
                    <h5 class="modal-title" id="exampleModalLabel"> </h5>
                    <button type="button" class="close" @click="userStillTyping = false"  data-target="#confirmModal" data-toggle="modal">
                            <span aria-hidden="true"> &times; </span>
                    </button>
                  
                </div> -->
                
              
                <div class="modal-body">
                  <div v-show="fillTestimony">
                    <h4 class="head-font  text-center mb-2">{{tran.rate_us}}</h4>

                    <p class="text-center">{{tran.let_us_know}}</p>

                    <div class="text-center pt-4" style="display:flex; justify-content:center; flex-direction:column">

                        <center>
                            <div style="width:40%; border-top: 1px solid #FDC23C;">
                            </div>
                        </center>
                        <p class="pt-2"><b>{{tran.rate_your_expe}}</b></p>
                    </div>

                    <!-- <div class="text-center rate-div">
                        <fieldset class="rating text-center">
                            <input type="radio" id="star51" name="rating" value="5" :checked="getStarRate() == 5"/>
                            <label class="full" for="star51"  title="Awesome - 5 stars" @click="highStarMethod(5)"></label>

                            <input type="radio" id="star41" name="rating" value="4" :checked="getStarRate() == 4" />
                            <label class = "full" for="star41" @click="highStarMethod(4)"></label>

                            <input type="radio" id="star31" name="rating" value="3" :checked="getStarRate() == 3" /><label class = "full" for="star31" @click="highStarMethod(3)"></label>

                            <input type="radio" id="star21" name="rating" value="2" :checked="getStarRate() == 2" />
                            <label class="full" for="star21" @click="lowStarMethod(2)"></label>

                            <input type="radio" id="star11" name="rating" value="1"  :checked="getStarRate() == 1"/><label class = "full" for="star11"  @click="lowStarMethod(1)" ></label>
                        </fieldset>
                    </div> -->

                    <div class="text-center pt-1" style="display:flex; justify-content:center; flex-direction:column">

                        <center>
                            <div style="width:60%; border-top: 1px solid #FDC23C;"></div>
                            <div style="width:20%; border-top: 1px solid #FDC23C;" class="mt-3"></div>
                        </center>

                    </div>

                    <div class="mt-3 mb-3">
                        <p class="text-center animated fadeIn" v-if="highStar"> <b>Nice!</b> {{tran.nice_do_you}} </p>

                        <p class="text-center animated fadeIn" v-if="lowStar"> <b>Ouch!</b> {{tran.ouch_please}} </p>
                    </div>

                    <div class="mt-1">
                        <textarea name="" id="" cols="30" v-model="testimonialDataFromStore.testimony" rows="4" style="width:100%; border-radius:5px !important" minlength="3" maxlength="250" placeholder="Type here"></textarea>
                    </div>
                                 <div class="com-pub mt-2">
                                  <div class="custom-control text-center custom-checkbox my-1 mt-4 mr-sm-2">
                                                                    <input type="checkbox" class="custom-control-input"  v-model ="testimonialData.share" id="customControlInline" >
                                                                    <label class="custom-control-label" title="Make my comments public" for="customControlInline"><b>Make my comments public</b></label>

                                                                </div>

                                               <p class="text-center pt-2">Your testimonial helps inform others to invest healthy.</p>
                                     </div>

                    <p class="drift-open-chat text-center pt-3" style="text-decoration:underline; cursor:pointer"> {{ tran.contact_sup }} </p>
                         </div>


 
                </div>
        
                <div class="modal-footer" v-show="shareNext" @click="shareOnSocialMedia" :class="{'fade-elem':testimonialDataFromStore.rating == '' || testimonialDataFromStore.testimony == '' }">
                    <p> {{ tran.next }} </p>
                </div>

                <div class="modal-footer" @click="submitTestimonial" v-show="submitTestimony" :class="{'fade-elem':testimonialDataFromStore.rating == '' || testimonialDataFromStore.testimony == '' }">
                    <p>{{ loader ? '' : tran.submit}}
                    <span class="loader" v-if="loader" style="top:94%"></span>
                    </p>
                </div>

                <div class="modal-footer" @click="closeTestimonial" v-show="!fillTestimony">
                    <p>{{ tran.done}}</p>
                </div>
            </div>
        </div>
    </div>

                                                                        </div>
                                                                    </div>
                </template>
                <template v-if="searchString !== ''">
                    <searchProduct />
                </template>
            </div>
        </div>
    </Wrapper>
             
</div>
</template>

<script>
import Navbar from '../partials/Navbar'
import Sidebar from '../partials/Sidebar'
import Footer from '../partials/Footer'
import Wrapper from '../partials/Wrapper'
import trans from "../../../translations";
import searchProduct from "./SearchProduct";

import {
    commonJs
} from './mixins/commonJs.js';
import {
    profileMixin
} from './mixins/profileMixin.js';

import {
    mapState
} from "vuex";
import PictureInput from 'vue-picture-input'
import { setTimeout } from 'timers';

export default {
    mixins: [commonJs, profileMixin],
    data() {

        return {
        }
    },

    
    components: {
        Navbar,
        Footer,
        Sidebar,
        Wrapper,
        PictureInput,
        searchProduct
    },

  
}
</script>

<style  lang="scss" scoped>
.no-border-phone{
    border: 1px solid transparent !important;
}

@media(max-width:399px){
.mob-div{
    div{

    flex-basis:100%;
    text-align:left !important;
    }
}
}
.mob {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 10px;
}

.mob-img {
  height: 100 !important;
}
.nav-tabs {
 max-width: 50%;
//   margin: 0 auto;
}

.project-tab {
  padding: 10%;
  margin-top: -8%;
}

.nav-tabs {
    border-bottom: 1px solid #fff;
}


.project-tab #tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #4F4F4F;
  background-color: transparent;
  border-color: transparent transparent transparent;
  border-bottom: 2px solid #0094ff !important;
  font-size: 16px;
}

.project-tab .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #4F4F4F;
  font-size: 16px;

}

.project-tab thead {
  background: #f3f3f3;
  color: #333;
}
.project-tab a {
  text-decoration: none;
}

.tab-div-2{
    align-items:center; background:#fff; border-radius:10px; padding:20px;
}

.disable-input{

    pointer-events: none !important;
    background: #FAFAFA;
    border: 0px solid;
    border-radius: 5px;
    padding: 5px;
    min-height: 45px;
    text-align: center;
    color: #000;
  
}

.enable-input{

    background: #fff;
    border: 1px solid #BDBDBD; 
    border-radius: 5px;
    padding: 5px;
    min-height: 45px;
    text-align: center;
    color: #000;

}


.enable-input:focus{
         border: 1px solid #0094FF; 
  }

  .security-modal{

      padding: 20px 100px !important;

      input{

          min-width: 100%;
      }
      @media(max-width:499px){
 padding: 20px 50px !important;
}
.eye-div i {
    position: absolute;
    left: 91% !important;
    top: 10px;
    cursor: pointer;
}
  }
</style>
