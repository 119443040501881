import { mapState } from "vuex";
import trans from "../../../../translations";
import http from "../../../http/index";
import api from "../../../apis/index";


export const profileMixin = {
    data() {
      return {
        nextOfKinLoader: false,
        editNextOfKin: false,
    hideEye: false,
        showEye: true,
    hideEyeForPas: false,
        showEyeForPas: true,
        hideInput: "password",
        hideInputForPassword:"password",
        oldPin: '',
        pin: '',
        newPin: '',
        retypeNewPin: '',
          oldPassword: '',
        newPassword: '',
        retypePassword:'',
        retypeCreateNewPin:'',
        userDocLoader: false,
        userDoc: true,
        tran: {},
        accountNumber: '',
        bankSelected: 1,
        bvn: '',
        phoneToken:'',
        accountName: '',
        firstName: '',
        lastName: '',
        select2: true,
        searchingAccountName: false,
        dob: '',
        nextOfKin: {
            nextk_name: '',
            nextk_relationship: '',
            nextk_email: '',
            nextk_phone: '',
            nextk_gender: ''
        },
        uti_bill: '',
        id_doc: '',
        prof_pic: '',
        userHaselectedBank: false,
              testimonialData:{
  testimony:'',
  rating:'',
  share:true,
},
    userisNotEditingPhone:true,
verifyingPhone:false,
numberUnchanged:true,
fillTestimony:true,
shareTestimony:false,
shareNext:false,
submitTestimony:true,
    showModal:true,
userStillTyping:true,
lowStar: false,
        highStar: false,
    
        verifyingToken:false
    }
      


    },

    methods: {
        userWantsToEditPhone(){
        
        this.numberUnchanged = !this.numberUnchanged
        
        },
        userClicksOnChangePhone(){
        
        this.numberUnchanged = true
            $('#verifyPhone').modal('show');
        
        
        },
                    ChangePhoneNumber() {
        let vm = this
                       this.verifyingPhone = true
        
                        http.post(api.updatePhone, {
        
                            phone:this.userProfileInfo.phone
                        })
                            .then(response => {
                                this.$helpers.log(response);
                                   this.verifyingPhone = false
                                            this.numberUnchanged = false
                            
                            })
        
                            .catch( error => {
        
                                vm.verifyingPhone = false
                                this.$helpers.log(error.response)
            
             this.$toast.warn({
                  timeOut: 5000,
                  icon: require("../../../assets/images/cautionicon.png"),
                   title: this.tran.sorry,
                  message:
                    error.response.data.message,
                 position: "top center"
                });
                            })
                    
                },
                     verifyPhoneToken() {
        
                        this.verifyingToken = true
        
                        http.post(api.verifyPhoneToken, {
        
                            token:this.phoneToken
                        })
                            .then(response => {
                                this.$helpers.log(response);
                                   this.verifyingToken = false
        
                               this.$toast.success({
                                title: this.tran.success,
                                icon: require("../../../assets/images/successicon.png"),
                                timeOut: 5000,
                                message: "Phone number changed and verified",
                                position: "top center"
                            });
        
                         
        
                    this.$store.dispatch('auth/getUserProfile');
                                 $('#verifyPhone').modal('hide');
        
                            })
        
                            .catch( error => {
        
                                this.verifyingToken = false
                                this.$helpers.log(error.response)
            
             this.$toast.warn({
                  timeOut: 5000,
                  icon: require("../../../assets/images/cautionicon.png"),
                   title: this.tran.sorry,
                  message:
                    error.response.data.message,
                 position: "top center"
                });
                            })
                    
                },
                     verifyPhoneNumber() {
              
                        this.verifyingPhone = true
                        http.patch(api.verifyPhone, {
                            
                            phone:this.userProfileInfo.phone
                        })
                            .then(response => {
                            $('#verifyPhone').modal('show');
                                this.$helpers.log(response);
                                this.verifyingPhone = false
                            })
        
                            .catch(error => {
                           this.verifyingPhone = false
                                this.$helpers.log(error.response)
                            })
                    
                },
                      changePassword() {
        

if(this.newPassword !== this.retypePassword){

    this.$toast.warn({
        timeOut: 5000,
        icon: require("../../../assets/images/cautionicon.png"),
        message:
         'Please the new password and retype password field should be the same',
        position: "top center"
      });
      return
}

                    let apiData = {
                        old: this.oldPassword,
                        new: this.newPassword
                    }
        

                    this.$store.commit("auth/startLoader");
                    http.patch(api.changePassword, apiData)
                        .then(response => {
                            this.$helpers.log(response, 'Change password');
                            this.$toast.success({
                                title: this.tran.success,
                                icon: require("../../../assets/images/successicon.png"),
                                timeOut: 5000,
                                message: "Password changed successfully",
                                position: "top center"
                            });
                            this.$store.commit("market/stopPreloader");
                            $('#changePassword').modal('hide');
                            $('.modal-backdrop').remove();
                            this.$store.commit("auth/stopLoader");
                            this.newPassword = ''
                            this.retypePassword = ''
                        })
                        .catch(error => {
                            this.$store.commit("auth/stopLoader");
                            this.$helpers.log(error.response);
                            if (error.response !== undefined) {
                                this.$toast.warn({
                                    title: this.tran.sorry,
                                    icon: require("../../../assets/images/cautionicon.png"),
                                    timeOut: 5000,
                                    message: error.response.data.message,
                                    position: "top center"
                                });
                            }
        
                        });
                },
                changePhoneNumber(){
        
        
                    this.userisNotEditingPhone = false
                    $('#phoneNumber').focus()
                },
        getStarRate(){
        if(this.testimonialDataFromStore !== '' && this.testimonialData.rating == ''){
        
            return this.testimonialDataFromStore.rating
        }
        
        },
                
         closeTestimonial(){
                  $('.modal-backdrop').removeClass("modal-backdrop")
                this.fillTestimony = true
                this.shareNext = false
                this.submitTestimony = true
                 this.showModal = false
                 
         setTimeout(()=>{
        
            this.showModal = true
         }, 500)
              
                },
                shareOnSocialMedia(){
            
        this.testimonialData.testimony = this.testimonialDataFromStore.testimony
        if(this.testimonialData.rating == ''){
        
        this.testimonialData.rating = this.testimonialDataFromStore.rating
        
        }
                    this.$store.dispatch('auth/submitTestimonial', this.testimonialData)
        
                this.fillTestimony = false
                this.shareNext = false
                },
                
                submitTestimonial() {
        
        
        this.testimonialData.testimony = this.testimonialDataFromStore.testimony
        
        if(this.testimonialData.rating == ''){
        
        this.testimonialData.rating = this.testimonialDataFromStore.rating
        
        }
                    this.$store.dispatch('auth/submitTestimonial', this.testimonialData)
                        $('.modal-backdrop').removeClass("modal-backdrop")
                        this.showModal = false
                    
                        setTimeout(()=>{
        
                            this.showModal = true
                        }, 2000)
                },
                    goBackToTestimonial(){
                        
                    this.fillTestimony = true
                    this.submitTestimony = true
                    },




                    gotoPersonalProfile(){
                        
                        this.$router.push('/profile/personal')
                        },
                    gotoSecurityProfile(){
                        
                        this.$router.push('/profile/security')
                        },
                        openChangePinModal(){

                            this.oldPin = ''
                            this.newPin  = ''
                            this.retypeNewPin = ''
                            $('#changePinModal').modal('show');
                        },
                        openforgotPinModal(){

                            this.oldPin = ''
                         
                            $('#forgotPin').modal('show');
                        },
                        openChangePasswordModal(){

                            this.oldPassword = ''
                            this.newPassword  = ''
                            this.retypePassword = ''
                            $('#changePassword').modal('show');
                        },
                      
                      
          changePin() {
            if(this.newPin !== this.retypeNewPin){
            
                this.$toast.warn({
                    timeOut: 5000,
                    icon: require("../../../assets/images/cautionicon.png"),
                    message:
                     'Please the new PIN and retype new PIN field should be the same',
                    position: "top center"
                  });
                  return
            }

                    let apiData = {
                        old: this.oldPin,
                        new: this.newPin
                    }
        
                    this.$store.commit("auth/startLoader");
                    http.patch(api.pin, apiData)
                        .then(response => {
                            this.$helpers.log(response);
                             this.$store.commit("auth/stopLoader");
                            this.$toast.success({
                                title: this.tran.success,
                                icon: require("../../../assets/images/successicon.png"),
                                timeOut: 5000,
                                message: "Pin changed successfully",
                                position: "top center"
                            });
                            $('#changePinModal').modal('hide');
                            $('.modal-backdrop').remove();
                            this.$store.commit("auth/stopLoader");
                            this.newPin = ''
                            this.retypeNewPin = ''
                        })
                        .catch(error => {
                            this.$store.commit("auth/stopLoader");
                            this.$helpers.log(error.response);
                            if (error.response !== undefined) {
                                this.$toast.warn({
                                    title: this.tran.sorry,
                                    icon: require("../../../assets/images/cautionicon.png"),
                                    timeOut: 5000,
                                    message: error.response.data.message,
                                    position: "top center"
                                });
                            }
        
                        });
                },
                forgotPin() {
     
                    let apiData = {
                        password: this.oldPassword,
                       
                    }
        
                    this.$store.commit("auth/startLoader");
                    http.post(api.forgotPin, apiData)
                        .then(response => {
                            this.$helpers.log(response);
                             this.$store.commit("auth/stopLoader");
                            this.$toast.success({
                                title: this.tran.success,
                                icon: require("../../../assets/images/successicon.png"),
                                timeOut: 5000,
                                message: 'A reset link will be sent to you in '+ response.data.data.wait_time+ ' hours',
                                position: "top center"
                            });
                            $('#forgotPin').modal('hide');
                            $('.modal-backdrop').remove();
                            this.$store.commit("auth/stopLoader");
                            this.oldPassword = ''
                        
                        })
                        .catch(error => {
                            $('.modal-backdrop').remove();
                            $('#forgotPin').modal('hide');
                            this.$store.commit("auth/stopLoader");
                            this.$helpers.log(error.response);
                            if (error.response !== undefined) {
                                this.$toast.warn({
                                    title: this.tran.sorry,
                                    icon: require("../../../assets/images/cautionicon.png"),
                                    timeOut: 8000,
                                    message: error.response.data.message,
                                    position: "top center"
                                });
                            }
                       
                        });
                },
            showInputText() {
        
                    this.hideInput = "text";
                    this.showEye = false;
                    this.hideEye = true;
        
                },
                hideInputText() {
        
                    this.hideInput = "password";
                    this.showEye = true;
                    this.hideEye = false;
        
                },
            showInputTextForPas() {
        
                    this.hideInputForPassword = "text";
                    this.showEyeForPas = false;
                    this.hideEyeForPas = true;
        
                },
                hideInputTextForPas() {
        
                    this.hideInputForPassword = "password";
                    this.showEyeForPas = true;
                    this.hideEyeForPas = false;
        
                },
                removeNotification(){
                   
                        localStorage.setItem("barNotification", this.showNotification)
                    },
        
        
                lowStarMethod(rating) {
                    this.lowStar = true
                    this.submitTestimony = true
                    this.highStar = false
                    this.testimonialData.rating = rating
                    this.shareTestimony = false
                    this.shareNext = false
                },
        
                highStarMethod(rating) {
                    this.lowStar = false
                    this.highStar = true
                        this.shareTestimony = true
                        this.shareNext = true
                        this.submitTestimony = false
                    this.testimonialData.rating = rating
                },
        
        
                    createPin() {


                        if(this.pin !== this.retypeCreateNewPin){

                            this.$toast.warn({
                                timeOut: 5000,
                                icon: require("../../../assets/images/cautionicon.png"),
                                message:
                                 'Please the pin and retype pin field should be the same',
                                position: "top center"
                              });
                              return
                        }
                        

              let apiData = {
                pin: this.pin
              };
        
              this.$store.commit("auth/startLoader");
              http
                .post(api.pin, apiData)
                .then(response => {
                  this.$helpers.log(response);
                   this.$store.commit("auth/stopLoader");
                  this.$toast.success({
                    title: this.tran.success,
                    icon: require("../../../assets/images/successicon.png"),
                    timeOut: 5000,
                    message: "Pin created successfully",
                    position: "top center"
                  });
                  $('#createPinModal').modal('hide');
                  $('.modal-backdrop').remove();
                  this.pin = ''
                  this.retypeCreateNewPin = ''

                  this.$store.commit("auth/stopLoader");
                  this.$store.dispatch("auth/getUserProfile");
                })
                .catch(error => {
                  this.$store.commit("auth/stopLoader");
                  this.$helpers.log(error.response);
                  if (error.response !== undefined) {
                    this.$toast.warn({
                      title: this.tran.sorry,
                      icon: require("../../../assets/images/cautionicon.png"),
                      timeOut: 5000,
                      message: error.response.data.message,
                      position: "top center"
                    });
                  }
                });
            },
               
        
                stopEditNextOfKin() {
                    this.editNextOfKin = false
                },
        
                editNextOfKinMethod() {
                    this.editNextOfKin = true
                },
        
                updateNextOfKin() {
                    this.nextOfKinLoader = true
                    let nextOfKin = {
                        nextk_name: this.userProfileInfo.next_kin_name,
                        nextk_relationship: this.userProfileInfo.next_kin_relationship,
                        nextk_email: this.userProfileInfo.next_kin_email,
                        nextk_phone: this.userProfileInfo.next_kin_phone,
                        
                    }
        
        
        
                    http.post(api.submitKyc, nextOfKin)
                        .then(response => {
        
        
                            this.$helpers.log(response);
                            this.nextOfKinLoader = false;
                            this.editNextOfKin = false;
                            this.$toast.success({
                                title: this.tran.success,
                                icon: require("../../../assets/images/successicon.png"),
                                timeOut: 5000,
                                message: "Next of kin updated successfully",
                                position: "top center"
                            });
                        })
        
        
                        .catch(error => {
                            this.nextOfKinLoader = false
                            this.$helpers.log(error.response);
                            this.editNextOfKin = false;
                            this.$toast.warn({
                                title: this.tran.sorry,
                                icon: require("../../../assets/images/cautionicon.png"),
                                timeOut: 5000,
                                message: error.response.data.message,
                                position: "top center"
                            });
                        });
                },
                onChange() {
                    this.id_doc = this.$refs.id_doc.file
        
                    this.userDoc = false
                },
        
                saveUtiBill() {
                    this.userDoc = false
                    this.uti_bill = this.$refs.uti_bill.file
        
                },
        
                stopEditUserDoc() {
                    this.userDoc = true
                },
        
                saveIdDoc() {
        
                    this.uti_bill = this.$refs.uti_bill.file
        
                },
        
                saveProfilePic() {
                    this.prof_pic = this.$refs.prof_pic.file
                    this.uploadProfilePic()
                },
                uploadProfilePic() {
                    // this.userDocLoader = true
                    let docData = {
                        profile_pic: this.prof_pic,
                    }
        
                    let form_data = new FormData();
        
                    for (let key in docData) {
                        form_data.append(key, docData[key]);
                    }
        
                    let apiDoc = {
                        content: form_data
                    };
        
                    http.post(api.submitKyc, apiDoc)
                        .then(response => {
                            this.$helpers.log(response);
                            this.$toast.success({
                                title: this.tran.success,
                                icon: require("../../../assets/images/successicon.png"),
                                timeOut: 5000,
                                message: "Profile picture uploaded successfully",
                                position: "top center"
                            });
        
                        })
                        .catch(error => {
                            this.$helpers.log(error.response);
                        });
        
                },
        
                getBankNames() {
                    if (this.userProfileInfo.bank_account_no.length == 10) {
                        this.searchingAccountName = true;
                        http.get("api/v1/account/bank/name" + "?account_number=" + this.userProfileInfo.bank_account_no + "&" + "bank_id=" + this.bankSelected)
                            .then(response => {
                                this.$helpers.log(response, 'bank name');
                                this.searchingAccountName = false;
                                this.accountName = response.data.data.name;
                                this.$store.commit('auth/saveAccountName', this.accountName)
                            })
        
                            .catch(error => {
                                this.searchingAccountName = false;
                                this.$helpers.log(error.response)
                                
                        this.$store.commit("domElements/saveNotificationMessage", error.response.data.message);
                        this.$store.commit("domElements/openNotificationMessage");
                            })
                    }
                },
        
                updateUserDoc() {
                    this.userDocLoader = true
                    let docData = {
                        id_doc: this.id_doc,
                        utilb: this.uti_bill
                    }
        
                    let form_data = new FormData();
        
                    for (let key in docData) {
        
                        form_data.append(key, docData[key]);
                    }
        
                    let apiDoc = {
                        content: form_data
                    };
        
                    http.post(api.submitKyc, apiDoc)
                        .then(response => {
                            this.$helpers.log(response);
                            this.userDocLoader = false;
                            this.$toast.success({
                                title: this.tran.success,
                                icon: require("../../../assets/images/successicon.png"),
                                timeOut: 5000,
                                message: "Document uploaded successfully",
                                position: "top center"
                            });
        
                            this.userDoc = true
                        })
                        .catch(error => {
                            this.userDocLoader = false
                            this.$helpers.log(error.response);
                            this.userDoc = true
                        });
                },
        
                getBankSelected(e) {
                    this.bankSelected = e.target.value
                    this.userHaselectedBank = true
        try{
          if(this.userProfileInfo.bank_account_no.length == 10){
        
                        this.getBankNames()
                    }
        }
        
        catch{
        
        
        }
                  
                },
        
                updateBankDetails() {

                   
                    var bankIdSelected = this.bankSelected
                    let vm = this
                    if (vm.userProfileInfo.bank_account_name !== null && this.userHaselectedBank == false) {
        
                        this.bankNames.filter(function (bank) {
                            if (bank.name === vm.userProfileInfo.bank_name) {
                                bankIdSelected = bank.id
        
                            }
                        })
                    }
        
                    let bankDetails = {
                        bank_id: bankIdSelected,
                        account_name: this.userProfileInfo.bank_account_name,
                        account_number: this.userProfileInfo.bank_account_no,
                        bvn: this.bvn,
                        //dob: this.userProfileInfo.dob,
                    }

                   
                        if(this.userProfileInfo.bvn == '1' || this.userProfileInfo.bvn !== '0' && this.userProfileInfo.bvn !== null){
                       delete bankDetails.bvn 
                         }

                         this.$helpers.log(bankDetails)
                         //return
        
                    this.$helpers.log(bankDetails, 'bank_details')
                    this.$store.dispatch('auth/updateUserBankDetail', bankDetails)
                    this.select2 = false;
                    this.userHaselectedBank = false
                    // this.bankSelected = '';

                    if(this.$route.path === '/savebvn'){
                        this.$router.push('/market')
                    }
                  
                },
        
        
                updateUserProfile() {
        
                    let profileDetails = {
                        first_name: this.userProfileInfo.first_name,
                        last_name: this.userProfileInfo.last_name,                
                    }
        
                    this.$store.dispatch('auth/updateUserProfile', profileDetails)
                },
        
                editUserProfile() {
                    this.$store.commit('auth/startEditProfile')
                },
        
                stopEditUserProfile() {
        
                    this.$store.commit('auth/stopEditProfile')
                },
        
                editUserBankDetail() {
                    this.$store.commit('auth/startEditBankDetail')
                    this.select2 = true;
        
                },
        
                stopEditUserBankDetail() {
                    this.$store.commit('auth/stopEditBankDetail')
        
                    if(this.userProfileInfo.bvn == null || this.userProfileInfo.bvn == ''){
                        this.bvn = ''
                    }
                },
        
            
        
                sendGuestTosignUp() {
                    this.$router.push("/signup");
                }
        
            },
            computed: {
                ...mapState({
        
                    accountOverview: state => state.market.accountOverview,
                    bankNames: state => state.auth.bankNames,
                    editProfileLoader: state => state.auth.editProfileLoader,
                    editBankDetailLoader: state => state.auth.editBankDetailLoader,
                    bankNames: state => state.auth.bankNames,
                    referraLength: state => state.auth.referraLength,
                    referralTotalAmount: state => state.auth.referralTotalAmount,
                    editProfile: state => state.auth.editProfile,
                    editBankDetail: state => state.auth.editBankDetail,
                    testimonialDataFromStore: state => state.auth.testimonialDataFromStore,
                    // bankAccountStatus: state => state.auth.editBankDetail,
                           loader: state => state.auth.loader,
        
                }),
        
                returnBankSelected() {
                    return this.bankSelected
                },
        
                returnFirstName() {
        
                    return this.firstName
                },
                returnLastName() {
        
                    return this.lastName
                },
            },
            mounted() {
        
                this.tran = trans;
        
                let heading = {
        
                    main: this.tran.profile,
                }
        
                this.$store.commit('market/savePageHeading', heading)
        
                this.$store.dispatch('auth/getBankList');
                this.$store.dispatch('auth/getTestimonial');
        
        
                setTimeout(() => {
                    $("input").focus(function () {
                        $(this)
                            .parents(".form-group")
                            .addClass("focused");
                    });
        
                    $("input").blur(function () {
                        var inputValue = $(this).val();
                        if (inputValue == "") {
                            $(this).removeClass("filled");
                            $(this)
                                .parents(".form-group")
                                .removeClass("focused");
                        } else {
                            $(this).addClass("filled");
                        }
                    });
                }, 1000);
                this.$store.commit('auth/saveCurrentRoute', this.$route.path)
            }
        
}
